import React, {useEffect, useState} from "react";
import {AutoComplete, Spin, Table} from "antd";

import './ModalDrillDownOrganization.scss';

const ModalDrillDown = ({
  getBenchmarkDrillDownForChildOrganizations,
  indicator,
  organization,
  periodStart,
  periodEnd,
  isFetching,
  settingsBenchmark,
  t,
}) => {

  const [drillDownData, setDrillDownData] = useState(null);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState(null);

  useEffect(() => {
    getBenchmarkDrillDownForChildOrganizations({
      batch: true,
      indicator,
      organizations: [organization?.id],
      period_start: periodStart,
      period_end: periodEnd,
      calculation_types: ['current'],
    })
      .then((response) => {
        setDrillDownData(response.data);
      })
      .catch((e) => console.error(e))
  }, [])

  useEffect(() => {
    const columns = [
      {
        title: t('name'),
        dataIndex: 'name',
        key: 'name',
        render: (text) => <p>{text}</p>
      },
      {
        title: t('result'),
        dataIndex: 'current',
        key: 'current',
        render: (text) => <p>{text}</p>
      }
    ];

    setColumns(columns);
  }, [settingsBenchmark])

  useEffect(() => {
    if (!!!drillDownData)
      return;

    const current = drillDownData?.values?.current?.[indicator];
    if (!!!current)
      return;

    const data = [];

    for (let i = 0; i < current?.full?.length; i++) {
      const sortData = {
        key: i,
        name: current?.full?.[i]?.orgName,
        current: current?.full?.[i]?.result,
      }

      data.push(sortData);
    }

    setData(data);
  }, [drillDownData])

  return (
    <div className="modal__wrapper">
      {
        isFetching ? <Spin size={'large'} /> : 
        <>
          <div style={{fontSize: '1.2rem', marginBottom: '0.5em', width: '100%'}}>
            {drillDownData?.values?.current?.[indicator]?.question}
          </div>
          <Table bordered columns={columns} dataSource={data} pagination={false} style={{ width: '100%' }} />
        </>
      }
    </div>
  );
};

export default ModalDrillDown;
